import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            recipient_name: 'Test',
            recipient_zipcode: '1217EH',
            recipient_housenum: '59',
            recipient_street: '\'s Gravelandseweg',
            recipient_city: 'Hilversum',

            name: 'Test2',
            emailaddress: 'test@test.nl',
            privacy_check: true,
            av_check: true
        }
    },

    getters: {
        all: state => state.entry
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            if( payload )
            {
                context.commit( 'assign', payload );
            }

            let data = new FormData();

            for( let item in context.getters.all )
            {
                data.append( item, context.getters.all[ item ] );
            }

            return new EntryProxy()
                .create( data )
                .then( response =>
                {
                    // context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
