<template>
    <div class="main-layout">
        <div class="header">
            <img class="main-logo" src="/static/img/main-logo.png" />
            <h1>Verras je moeder <br/>met een <br/>videoboodschap</h1>
        </div>
        <div class="main-content">
            <slot></slot>
        </div>

        <div class="footer">
            <ul>
                <li><a href="https://www.nestle.nl/contactgegevens" target="_blank">Contact</a></li>
                <li><a href="https://www.nestle.nl/info/privacyverklaring" target="_blank">Privacy Statement</a></li>
                <li><a href="https://www.nestle.nl/info/cookies%20policy" target="_blank">Cookies</a></li>
                <li><a href="https://www.nestle.nl/info/wettelijke-bepalingen" target="_blank">Wettelijke bepalingen</a></li>
            </ul>

            <div class="social-wrapper">
                <a href="https://nl-nl.facebook.com/NestleNederland/" target="_blank"><img src="/static/img/fb-icon.png" /></a>
                <a href="https://www.youtube.com/channel/UC9OWG9NiIftK-oHMBakcYzA" target="_blank"><img src="/static/img/yt-icon.png" /></a>
                <a href="https://twitter.com/nestlenederland" target="_blank"><img src="/static/img/tw-icon.png" /></a>
            </div>

            <a class="nestle-fold" href="https://www.nestle.com/" target="_blank"><img src="/static/img/nestle-fold.png" /></a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .main-layout{
        text-align:center;
    }
</style>

<script>
    export default {

    };
</script>
