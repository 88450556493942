<template>
    <div id="app">
        <ynk-layout>
            <transition mode="out-in"
                        @enter="getAnimateIn"
                        @leave="getAnimateOut"
                        :css="false"
                        appear>
                <router-view :key="$route.path" ref="currentView"></router-view>
            </transition>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { TimelineMax } from '@/vendor';
    import Main from '@/app/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
            },

            getAnimateOut( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( typeof this.$refs.currentView.getAnimateOut === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateOut(), 0 );
                }
            }
        }
    };
</script>
